import MediathekTeaser, { MediaTeaserProps } from 'components/mediathekTeaser/mediathekTeaser';
import React, { useState } from 'react';
import Teaser, { TeaserData } from './teaser';
import './teaserGrid.scss';

interface Props {
  data?: { node: TeaserData }[];
  mediathek?: {
    node: MediaTeaserProps;
  }[];
}

const TeaserGrid: React.FC<Props> = (props) => {
  const [paging, setPaging] = useState<number>(1);

  const pagingLength = 9;

  return (
    <div className="teaser-grid">
      <div className="wrapper">
        <div className="grid-row">
          {!!props.data &&
            props.data.map((item, key) => {
              if (key <= paging * pagingLength - 1) {
                return (
                  <div className="teaser-grid__cell grid-cell sm-12 md-6 lg-4" key={key}>
                    <Teaser {...item.node} />
                  </div>
                );
              }
              return null;
            })}
          {!!props.mediathek &&
            props.mediathek.map((item, key) => {
              if (key <= paging * pagingLength - 1) {
                return (
                  <div className="teaser-grid__cell grid-cell sm-12 md-6 lg-4" key={key}>
                    <MediathekTeaser {...item.node} />
                  </div>
                );
              }
              return null;
            })}
        </div>
        {((!!props.data && props.data.length > paging * pagingLength) || (!!props.mediathek && props.mediathek.length > paging * pagingLength)) && (
          <div className="grid-row">
            <div className="grid-cell sm-12 teaser-grid__loadmore">
              <button onClick={() => setPaging(paging + 1)} className="link-button">
                Mehr laden
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TeaserGrid;
