import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation } from "@reach/router";
import classNames from "classnames";
import { TeaserData } from "components/teaser/teaser";
import TeaserGrid from "components/teaser/teaserGrid";
import { graphql } from "gatsby";
import { pageContextProps } from "utils/props.utils";
import "./publications.template.scss";
import imagesLoaded from "imagesloaded";
import { handlePageLoaded } from "components/trans/transLink";
import SEO from "components/seo/seo";

interface Props {
  pageContext: pageContextProps;
  data: {
    posts: {
      edges: {
        node: TeaserData;
      }[];
    };
    categories: {
      nodes: {
        name: string;
        slug: string;
        categoryFields: {
          color: string;
        }
      }[];
    };
  };
}

const PublicationsTempalte: React.FC<Props> = (props) => {
  const [categories, setCategories] = useState<any[]>([]);
  const [posts, setPosts] = useState<any[]>([]);
  const [categorySlug, setCategorySlug] = useState<string>("");
  const location = useLocation();

  const mainRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  // pan
  const categoriesRef = useRef<HTMLDivElement>(null);
  const [preventDrag, setPreventDrag] = useState<boolean>(false);

  useEffect(() => {
    const main = mainRef.current;
    !!main &&
      imagesLoaded(
        document.querySelectorAll("[data-preload]"),
        handlePageLoaded
      );
    setIsLoaded(true);
  }, []);

  useLayoutEffect(() => {
    setCategories([
      // {
      //   name: "Alle",
      //   slug: location.pathname.replaceAll('/', ''),
      // },
      ...props.data.categories.nodes,
    ]);
  }, [props.data.categories.nodes]);

  useLayoutEffect(() => {
    const hash = location.hash.replace("#", "");
    const isCategories = props.data.categories.nodes.filter(
      (item) => item.slug === hash
    );

    if (isCategories.length) {
      setCategorySlug(hash);
    } else {
      setCategorySlug("");
    }
  }, [location.hash]);

  useLayoutEffect(() => {
    const filteredPosts = props.data.posts.edges.filter((item) => {
      return (
        categorySlug.length === 0 ||
        !!item.node.terms?.nodes.filter(
          (item: any) => item.slug === categorySlug
        ).length
      );
    });
    setPosts(filteredPosts);
  }, [categorySlug]);


  // PAN END
  useEffect(() => {
    const handleResize = () => {
      const dragElement = categoriesRef.current;
      const parent = dragElement?.parentElement;
      if (!!dragElement && !!parent) {
        const prevent = dragElement.clientWidth <= parent.clientWidth;
        setPreventDrag(prevent);
        prevent && (dragElement.style.left = "0");
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.addEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className={classNames("template-publications", "publications-template", {
        "is-loading-complete": isLoaded,
        "is-prevent-drag": preventDrag,
      })}
      id={props.pageContext.id}
      ref={mainRef}
    >
      <SEO {...props.pageContext.seo} />
      <section className="section">
        <div className="section-header">
          <h5 className="section-header__title">{props.pageContext.title}</h5>
        </div>
          <div className="publications-categories">
            <div className="publications-categories__inner">
              <div className="publications-categories__row" ref={categoriesRef}>
                {categories.map((item, key) => {
                  return (
                    <div className="publications-categories-item" key={key}>
                      <a
                        href={
                          item.slug === categorySlug
                            ? "#publikationen"
                            : `#${item.slug}`
                        }
                        key={key}
                        className={classNames(
                          "publications-categories-item__link",
                          {
                            "is-custom-active": item.slug === categorySlug,
                            "is-passive":
                              !!categorySlug &&
                              categorySlug !== "publikationen" &&
                              item.slug !== categorySlug,
                          }
                        )}
                      >
                        <span className="publications-categories-item__content" style={{backgroundColor: `#${item.categoryFields.color}`}}>
                          {item.name}
                        </span>
                        <div className="publications-categories-item__close" style={{backgroundColor: `#${item.categoryFields.color}`}}>
                          <span />
                        </div>
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        <TeaserGrid data={posts} />
      </section>
    </div>
  );
};
export default PublicationsTempalte;

export const query = graphql`
  query ($id: String!, $language: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      title
    }
    posts: allWpPost(sort: { order: DESC, fields: date }) {
      edges {
        node {
          id
          uri
          title
          postNews {
            titleForOverview
          }
          featuredImage {
            node {
              ...FeaturedImage
            }
          }
          terms {
            nodes {
              name
              slug
              taxonomyName
              ... on WpCategory {
                categoryFields {
                  color
                }
              }
            }
          }
        }
      }
    }
    categories: allWpCategory(filter: { count: { gt: 0 } }) {
      nodes {
        name
        slug
        categoryFields {
          color
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
